import React, {useState, useEffect} from 'react'
import CampaignLayout from "../components/layout/campaignlayout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import LandingHerov2 from '../components/landingherov2'
import RegistrationModal from "../components/registrationmodalv3"
import RegisteredModal from "../components/registeredmodal"


export default ({data}) => {

  const [showSurvey, setshowSurvey] =useState(false);
  const [modalIsOpen,setModalIsOpen] = useState(false);
  const [modalConfIsOpen,setModalConfIsOpen] = useState(false);

  useEffect(() => {
    console.log('***TEST***' + showSurvey)
    if (showSurvey){
      const timer = setTimeout(() => setModalIsOpenToTrue(), 5000);
      if (process.env.GATSBY_ACTIVE_ENV === 'development') {
        console.log('***Timer (5 seconds) started***')
      } 
      return () => clearTimeout(timer);
    }
  }, []);

  const setModalIsOpenToTrue =()=>{
    if (process.env.GATSBY_ACTIVE_ENV === 'development') {
      console.log('***setModalIsOpenToTrue has been called***')
    } 
    if (typeof window !== 'undefined') {
      document.documentElement.style.overflow = 'hidden';
      document.body.scroll = "no";
    }
    const modalCheck = typeof window !== 'undefined' && window.localStorage.getItem('stickchart:_sc_registered');
    if((modalCheck === 'true') || (modalCheck === 'dismissed')){
        //set registration modal state to false - do not show
        setModalIsOpen(false)
        //set registered modal state to true - show
        setModalConfIsOpen(false)
    }
    else {
        //set registration modal state to true - show
        setModalIsOpen(true)
        //set registered modal state to false - do not show
        setModalConfIsOpen(false)
    }
  }

  const setModalIsOpenToFalse =()=>{
      setModalIsOpen(false)
      setModalConfIsOpen(false)
      if (typeof window !== 'undefined') {
        document.documentElement.style.overflow = 'scroll';
        document.body.scroll = "yes";
      }
      
  }

  const confirmModal =()=>{
      setModalConfIsOpen(true)
  }


  const {
      allStrapiCustomLandings:{nodes:hero},
  } = data //destructure the data


    return (
        <CampaignLayout>
          <SEO title={hero[0].SEOtitle} description={hero[0].SEOdescription}/>
          <main class="landingpage">
              {/* start hero section */}
              <section class="section flex h-full sclg:h-screen overflow-hidden box-border w-full  bg-cover bg-gradient-to-tl from-sc-primary-blue to-white bg-no-repeat">
                  <div class="section flex w-full h-full sclg:h-screen bg-wave-bottom bg-bottom bg-no-repeat">
                      <div class="container flex items-center mx-auto w-full ">
                          {/* load hero component */}
                          <LandingHerov2 hero={hero} /> 
                          { /* <!--SVG Base (wave)--> */}
                      </div>
                      
                  </div>
              </section>

          </main>
          <RegistrationModal 
            displayModal={modalIsOpen}
            closeModal={setModalIsOpenToFalse}
            confirmModal={confirmModal}
            usertype='customer'
          />
          <RegisteredModal 
            displayModal={modalConfIsOpen}
            closeModal={setModalIsOpenToFalse}
            usertype='customer'
          />
        </CampaignLayout>
    )
}



export const query = graphql`
  {
  allStrapiCustomLandings(filter: {type: {eq: "customer"}}) {
    nodes {
      SEOdescription
      SEOtitle
      tagline
      heading
      body
      link
      image {
        publicURL
      }
    }
  }
}
`
